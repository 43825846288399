// Translations
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

function Index() {
	return null;
}


export const getServerSideProps = async ({ locale }) => ({
	props: {
		...await serverSideTranslations(locale, ['common']),
	},
});

export default Index;
